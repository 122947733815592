















































































import Vue from "vue";
import Drawer from "@web/components/modals/Drawer.vue";
import {
    mapActions,
    mapGetters
} from "vuex";
import { NEWS_MODULE_NAME } from "@web/store/news/news";
import {
    OPEN_NEWS,
    FETCH_MY_DRAFTS,
    FETCH_MY_NEWS
} from "@web/store/news/actions";
import PublishedNewsList from "@web/views/news/PublishedNewsList.vue";
import NewsRenderer,
{ NewsRendererMode } from "@web/views/news/NewsRenderer.vue";
import SidebarNewsTeaser from "@web/components/SidebarNewsTeaser.vue";
import { EverestNews } from "@backend/news/types";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import { INTRANET } from "@web/store/intranet/getters";
import PageHeader from "@web/components/PageHeader.vue";
import SidebarHeader from "@web/components/SidebarHeader.vue";
import IntentHandlerMixin from "@web/mixins/IntentHandlerMixin.vue";

export default Vue.extend<any, any, any, any>({
    name: "News",
    components: {
        SidebarHeader,
        SidebarNewsTeaser,
        Drawer,
        PublishedNewsList,
        NewsRenderer,
        PageHeader
    },
    mixins: [IntentHandlerMixin],
    computed: {
        ...mapGetters(INTRANET_MODULE_NAME, [INTRANET])
    },
    methods: {
        ...mapActions({
            loadDrafts: NEWS_MODULE_NAME + FETCH_MY_DRAFTS,
            loadMyNews: NEWS_MODULE_NAME + FETCH_MY_NEWS,
            editNews: NEWS_MODULE_NAME + OPEN_NEWS
        }),
        async openEditor() {
            this.$modal.show("news-renderer", {
                newsUid: "",
                mode: NewsRendererMode.CREATE
            });
        },
        editNews(newsUid: EverestNews) {
            this.$router.push(`/intranet/${this.intranet.uid}/news/id/${newsUid}`);
        },
        async openDrawer() {
            this.loadDrafts();
            this.loadMyNews();
            this.openModal("news-drawer");
        },
        openModal(name: string) {
            this.$modal.show(name);
        },
        handleCreateNewsIntent() {
            this.openEditor();
        }
    }
});
