<template>
    <article
        class="news-teaser"
        @click="$emit('click')"
    >
        <div class="teaser-content">
            <div class="text">
                <div
                    v-fade-in
                    class="titles"
                >
                    <h1 class="clamp">
                        {{ news.title }}
                    </h1>
                    <h2>{{ news.subtitle }}</h2>
                </div>
            </div>
            <div class="image-wrapper">
                <div
                    class="image depth-2"
                    :style="`background-image: url('${coverPictureSrc}')`"
                >
                    <div
                        v-if="news.coverPictureRef===COVER_PICTURE_NOT_SET || news.coverPictureRef===AUTHOR_IS_COVER_PICTURE"
                        v-fade-in.when="authorDisplayPhotoURL"
                        class="avatar"
                    >
                        <img
                            :src="authorDisplayPhotoURL"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="meta">
            <div
                v-if="!isDraft"
                class="date"
            >
                {{ $t("published") }} {{ formatDate(news.publishDate) }}
            </div>
            <div
                v-if="isDraft"
                class="last-edit"
            >
                {{ $t("last_edited") }}{{ formatLastEditedDate(news.lastEditedDate) }}
            </div>
        </div>
    </article>
</template>

<script>
import {
    newsService,
    profileService
} from "@web/services";
import { mapState } from "vuex";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import {
    friendlyFormatDateFromDayJs,
    toDayJs
} from "@web/lib/time-utils";

export const COVER_PICTURE_NOT_SET = "";
export const AUTHOR_IS_COVER_PICTURE = "AUTHOR_IS_COVER_PICTURE";
export default {
    name: "SidebarNewsTeaser",
    props: {
        news: {
            type: Object,
            default: () => {
            }
        },
        isDraft: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            imageUrl: "",
            authorDisplayName: "",
            authorDisplayPhotoURL: "",
            coverPictureSrc: "",
            COVER_PICTURE_NOT_SET,
            AUTHOR_IS_COVER_PICTURE
        };
    },
    computed: {
        ...mapState(INTRANET_MODULE_NAME, ["intranet"]),
    },
    mounted() {
        this.fetchAuthorDisplayPhotoURL();
        this.loadCoverPicture();
    },
    methods: {
        formatDate(inputDate) {
            if (!inputDate) {
                return "";
            }
            return friendlyFormatDateFromDayJs(toDayJs(inputDate));
        },
        formatLastEditedDate(inputDate) {
            if (!inputDate) {
                return "";
            }
            const dayJs = toDayJs(inputDate);
            return friendlyFormatDateFromDayJs(dayJs);
        },
        async loadCoverPicture() {
            if (this.news.coverPictureRef && this.news.coverPictureRef !== AUTHOR_IS_COVER_PICTURE && this.news.coverPictureRef !== COVER_PICTURE_NOT_SET) {
                this.coverPictureSrc = await newsService.getDownloadUrlForImage(this.intranet.uid, this.news.uid, this.news.coverPictureRef);
            }
        },
        async fetchAuthorDisplayPhotoURL() {
            if (this.intranet.uid && this.news.creatorUid) {
                const { photoURL } = await profileService.getBaseUserProfile(
                    this.intranet.uid,
                    this.news.creatorUid
                ).catch(() => profileService.fallbackProfile);
                this.authorDisplayPhotoURL = photoURL;
            }
        },
    }
};
</script>

<style lang="scss" scoped>

    .news-teaser {
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid var(--low-contrast, #{$light-grey});
        animation-delay: var(--stagger);

        &:last-child,
        &:last-of-type {
            border-bottom: none;
            margin-bottom: 0;
        }

        .teaser-content {
            display: flex;
            flex-direction: row;
            margin-bottom: 1rem;
        }

        .image-wrapper {
            position: relative;
            z-index: 1;
            transition: transform 0.2s ease;
            flex: 1 0 auto;
            width: 4rem;
            height: 3rem;
        }

        .image {
            background: no-repeat center/cover;
            background-color: var(--lowest-contrast, #{$off-white});
            border-radius: var(--border-radius);
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            .avatar {
                width: 2rem;
                height: 2rem;
            }

        }

        .text {
            flex: 4 1 auto;
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        .text + .image-wrapper {
            margin: 0 0 0 1rem;
        }

        .meta {
            display: flex;
            flex: 1 0 100%;
            align-items: center;
            flex-wrap: nowrap;
            font-weight: 500;
            font-size: 12px;
            line-height: 1.4;
            letter-spacing: 0.03em;
            opacity: 1;
            width: 100%;
            color: var(--high-contrast, #{$dark-mid-grey});
            margin: 0;

            .author {
                margin-right: 4px;
                color: var(--foreground, #{$black});
            }
            .date{
            color:var(--primary);
            }
        }

        h1 {
            font-weight: bold;
            font-size: 16px;
            line-height: 1.3;
            margin-bottom: 0.4rem;

            .pill {
                vertical-align: middle;
                position: relative;
                bottom: 1px;
            }
        }

        h2 {
            font-weight: normal;
            font-size: 0.85rem;
            line-height: 1.4;
            color: var(--high-contrast, #{$dark-mid-grey});
            margin-bottom: 0.5rem;
        }

    }

</style>
